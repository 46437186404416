<script>
import TableView from '@/components/TableVIew'
import { delAction, getAction, postAction } from '@/command/netTool'
import { InputNumber, message } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'
export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    listData: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: String,
      default: '',
    },
    showSave: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      list: [],
      listParams: {
        total: 1,
      },
    }
  },
  mounted() {
    this.list = this.listData
  },
  methods: {
    sumbit() {
      this.$emit('success')
    },
  },
  render() {
    const that = this
    return (
      <div>
        <div style={{ marginBottom: '10px' }}>{this.title}</div>
        <TableView
          dataSource={this.list}
          pagination={this.listParams}
          onChange={(data) => {
            this.getList({ current: data.current, size: data.pageSize })
          }}
          columns={[
            {
              dataIndex: 'productName',
              title: '门票名称',
            },
            {
              dataIndex: 'scenicName',
              title: '景点名称',
            },
            {
              dataIndex: 'linkShopName',
              title: '店铺名称',
            },
            {
              dataIndex: 'previewUrl',
              title: '预览图',
              align: 'center',
              customRender: function (text, records) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormUpload
                      item={{}}
                      value={records.previewUrl}
                      onChange={(data) => {
                        records.previewUrl = data
                      }}
                    />
                  </div>
                )
              },
            },
            {
              dataIndex: 'sort',
              title: '优先级',
              customRender: function (text, records, index, h) {
                return (
                  <InputNumber
                    value={text}
                    onChange={(e) => {
                      that.list[index]['sort'] = e
                    }}
                  />
                )
              },
            },
            {
              dataIndex: 'a6',
              title: '操作',
              type: 'buttonGroup',
              align: 'center',
              typeData: ({ records, index }) => {
                return [
                  {
                    name: '删除',
                    display: true,
                    type: 'pop',
                    popTitle: '确认是否删除吗?',
                    onClick: () => {
                      this.list.splice(index, 1)
                    },
                  },
                ]
              },
            },
          ]}
        />
      </div>
    )
  },
}
</script>
