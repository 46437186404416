<script>
import { Tabs } from 'ant-design-vue'
import { getAction } from '@/command/netTool'
import Item from './item'
import lodash from 'lodash'
export default {
  props: {
    relationList: {
      type: Array,
      default: () => [],
    },
    mode: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      list: [],
      action: 0,
      rows: this.relationList || [],
    }
  },
  mounted() {
    getAction('/config/farmPalmItem/getCategoryList').then((e) => {
      if (this.mode) {
        this.list = e.data.slice(0, 3)
      } else {
        this.list = e.data
      }
    })
  },
  methods: {
    sumbit() {
      let arr = []
      Object.keys(this.$refs).forEach((e) => {
        arr.push(...this.$refs[e].getData().rows)
      })
      arr = arr.map((item) => ({
        ...item,
        productId: item.productId || item.id,
      }))
      const list = lodash.unionBy(arr, 'productId')
      console.log('list', list, this.relationList)
      this.$emit('success', { rows: list })
    },

    getUrl(item) {
      switch (item.code) {
        case 'native_product':
          return '/commodity/farmNativeProduct/list'
        case 'ticket':
          return '/goods/farmTicket/getRelationList'
        case 'room':
          return '/goods/farmRoom/getHotelRoomList'
        case 'ticket_scenic':
          return '/farmTicketScenic/list'
        case 'hotel':
          return '/hotel/list'
        case 'custom_travel_route':
          return '/commodity/farmTravelRoute/getRelationList'
        case 'agritainment':
          return '/goods/farmAgritainment/list'
        case 'config_invest':
          return '/config/farmConfigInvest/list'
        case 'item_country_video': // 视频列表
          return '/farmCountryVideo/list'
        case 'wechat_video_channel': // 视频号
          return '/config/farmPalmItem/getWechatVideoChannelList'
        case 'activity': // 专题活动
          return '/farmActivity/list'
        case 'custom_classification': // 自定义分类
          return '/config/farmPalmItem/getBusinessCategoryList'
      }
    },
  },
  render() {
    return (
      <Tabs v-model={this.action}>
        {this.list.map((e, i) => {
          return (
            <Tabs.TabPane tab={e.name} key={i} forceRender={true}>
              <Item url={this.getUrl(e)} ref={'table' + i} relationList={this.relationList} item={e} />
            </Tabs.TabPane>
          )
        })}
      </Tabs>
    )
  },
}
</script>
