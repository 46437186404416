<script>
import apiTool from '@/command/apiTool'
import FormView from '../FormView'
export default {
  name: 'add-map-lnglat',
  props:{
    records:{
      type:Object,
      defalut:()=>{}
    },
  },
  data() {
    return {
      detail: {
        lnglat:""
      },
    }
  },
  mounted() {
    this.detail = this.records
  },
  methods: {
    getForm() {
      const formData = [
        // {
        //   name: '位置 （例：120.184349，30.254460）',
        //   type: 'row',
        //   cols: 22,
        //   gutter: 5,
        //   rules: [
        //     {
        //       required: true,
        //     },
        //   ],
        //   children: [
        //     {
        //       type: 'input',
        //       cols: 20,
        //       key: 'lnglat',
        //       label: '地址',
        //       placeholder: '请输入经纬度以中文逗号隔开',
        //       rules: [
        //         {
        //           required: true,
        //           type: 'string',
        //         },
        //       ],
        //     },
        //     {
        //       type: 'buttonRight',
        //       label: '定位',
        //       cols: 5,
        //       style: {
        //         width: '105px',
        //         marginLeft: ' -5px',
        //         borderRadius: ' 4px',
        //         backgroundColor: 'rgba(44, 140, 240, 1)',
        //       },
        //       props: {
        //         type: 'primary',
        //         icon: 'environment',
        //       },
        //       onClick: () => {
        //         apiTool.openMap({
        //           title: '打开地图',
        //           value: [],
        //           id:'312312',
        //           onSuccess: (data) => {
        //             this.detail.lnglat = `${data.lng},${data.lat}`
        //             this.detail.longitude = data.lng
        //             this.detail.latitude = data.lat
        //           },
        //         })
        //       },
        //     },
        //   ],
        // },
        {
          name: '地图初始缩放级别',
          key: 'initialLevel',
          type: 'input',
          props: {},
          cols: 8,
          rules: [
            {
              required: true,
              type: 'string',
            },
          ],
        },
        {
          name: '最低缩放级别',
          key: 'lowestLevel',
          type: 'input',
          props: {},
          cols: 8,
        },
        {
          name: '最高缩放级别',
          key: 'highestLevel',
          type: 'input',
          props: {},
          cols: 8,
        },
      ]

      return <FormView data={formData} form={this.detail} />
    },
    sumbit() {
      this.$emit('success', this.detail)
    },
  },
  render() {
    return this.getForm()
  },
}
</script>
<style lang="less" scoped>
</style>
