<script>
import TableView from '@/components/TableVIew'
import { getAction } from '@/command/netTool'
export default {
  data() {
    return {
      list: [],
      listParams: {
        total: 1,
      },
    }
  },
  mounted() {
    this.getList()
  },
  methods: {
    getList(current = 1, size = 10) {
      getAction(`/goods/farmTicket/page?upDown=0&current=${current}&size=${size}`).then((e) => {
        this.listParams = e.data
        this.list = e.data.records
      })
    },
    sumbit() {
      this.$emit('success', this.$refs.table.selectedRows)
    },
  },
  render() {
    return (
      <TableView
        ref={'table'}
        dataSource={this.list}
        pagination={this.listParams}
        onChange={(data) => {
          this.getList(data.current, data.pageSize)
        }}
        columns={[
          {
            dataIndex: 'productName',
            title: '门票名称',
          },
          {
            dataIndex: 'scenicName',
            title: '景点名称',
          },
          {
            dataIndex: 'linkShopName',
            title: '店铺名称',
          },
        ]}
      />
    )
  },
}
</script>
