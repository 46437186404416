<script>
import TableView from '@/components/TableVIew'
import { delAction, getAction, postAction } from '@/command/netTool'
import { InputNumber, Select, Input } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'

export default {
  props: {
    id: {
      type: String,
      default: '',
    },
    title: {
      type: String,
      default: '',
    },
    listData: {
      type: Array,
      default: () => [],
    },
    data: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      list: [],
      listParams: {
        total: this.listData.length,
      },
      farmGoods: [],
      specialList: [],
    }
  },
  mounted() {
    this.list = this.listData
    getAction('/config/farmGoodsCategory/list').then((res) => {
      this.farmGoods = res.data
    })
    getAction('/farmShowSpecial/getList').then((res) => {
      this.specialList = res.data
    })
  },
  methods: {
    sumbit() {
      this.$emit('success', this.list)
    },
  },
  render() {
    const that = this
    let defaultObj = [
      {
        dataIndex: 'productName',
        title: '门票名称',
      },
      {
        dataIndex: 'secondCategoryId',
        title: '二级分类',
        align: 'center',
        customRender: function (text, records, index) {
          if (records.secondCategoryCode == 'custom_native_product_category') {
            //农家特产
            return (
              <Select
                value={records.secondCategoryId}
                style={{ width: '100%', minWidth: '135px' }}
                onChange={(data) => {
                  const rows = [...that.list]
                  rows[index]['secondCategoryId'] = data
                  that.list = rows
                }}
                getPopupContainer={(e) => {
                  return e.closest('table')
                }}
              >
                {(that.farmGoods || []).map((e) => {
                  return <Select.Option value={e.id}>{e.name}</Select.Option>
                })}
              </Select>
            )
          } else if (records.secondCategoryCode == 'custom_show_special_detail') {
            //专题页
            return (
              <Select
                value={records.secondCategoryId}
                style={{ width: '100%', minWidth: '135px' }}
                onChange={(data) => {
                  const rows = [...that.list]
                  rows[index]['secondCategoryId'] = data
                  that.list = rows
                }}
                getPopupContainer={(e) => {
                  return e.closest('table')
                }}
              >
                {(that.specialList || []).map((e) => {
                  return <Select.Option value={e.id}>{e.showName}</Select.Option>
                })}
              </Select>
            )
          }
        },
      },
      {
        dataIndex: 'scenicName',
        title: '景点名称',
      },
      {
        dataIndex: 'linkShopName',
        title: '店铺名称',
      },
    ]

    if (this.data.itemConfigCode === 'wonderful_activity') {
      defaultObj = [
        {
          dataIndex: 'name',
          title: '活动名称',
        },
      ]
    }

    return (
      <div>
        <div style={{ marginBottom: '10px' }}>{this.title}</div>
        <TableView
          dataSource={this.list}
          pagination={this.listParams}
          // onChange={(data) => {
          //   this.getList({ current: data.current, size: data.pageSize })
          // }}
          columns={[
            ...defaultObj,
            {
              dataIndex: 'previewUrl',
              title: '预览图',
              align: 'center',
              customRender: function (text, records) {
                return (
                  <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                    <FormUpload
                      item={{}}
                      value={records.previewUrl}
                      onChange={(data) => {
                        records.previewUrl = data
                      }}
                    />
                  </div>
                )
              },
            },
            {
              dataIndex: 'sort',
              title: '优先级',
              customRender: function (text, records, index, h) {
                return (
                  <InputNumber
                    value={text}
                    onChange={(e) => {
                      const rows = [...that.list]
                      rows[index]['sort'] = e
                      that.list = rows
                    }}
                  />
                )
              },
            },
            {
              dataIndex: 'a6',
              title: '操作',
              type: 'buttonGroup',
              align: 'center',
              typeData: ({ records, index }) => {
                return [
                  {
                    name: '删除',
                    display: true,
                    type: 'pop',
                    popTitle: '确认是否删除吗?',
                    onClick: () => {
                      this.list.splice(index, 1)
                    },
                  },
                ]
              },
            },
          ]}
        />
      </div>
    )
  },
}
</script>
