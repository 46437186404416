import { showModal } from '@/command/apiTool'
import { deleteAction, getAction, postAction } from '@/command/netTool'
import { Input, Switch, message, Popover } from 'ant-design-vue'
import AddTicketsModal from '@/components/AddTicketsModal'
import AddTicketsTabsModal from '@/components/AddTicketsTabsModal'
import TickerListModel from '@/components/TickerListModel'
import AddMapLngLat from '@/components/AddMapLngLat'
import TabsTickerListModel from '@/components/TabsTickerListModel'
import { Select } from 'ant-design-vue'
import FormUpload from '@/components/FormView/FormUpload'
import apiTool from '@/command/apiTool'

function onFetchInit(data) {
  return new Promise((resolve) => {
    // 固定分页数据
    getAction(`/config/farmPalmItem/list?homeStyleType=${data.homeStyleType}`).then((result) => {
      // 自定义分页数据
      getAction('/config/farmPalmItem/getCustomList').then((e) => {
        // 自定义样式列表
        getAction('/config/farmPalmItem/getStyleConfigList').then((el) => {
          // 更多跳转
          getAction('/config/farmPalmItem/getJumpConfigList').then((config) => {
            resolve({
              form: {
                ...data,
                list: result.data,
                custom: e.data,
                styleList: el.data,
                config: config.data,
              },
              typeData: [],
            })
          })
        })
      })
    })
  })
}

function onSubmit({ detail, form }) {
  postAction('/config/farmPalmItem/update', form, '/api').then(() => {
    message.success('数据保存成功')
    postAction(
      '/config/farmPalmItem/saveOrUpdateBatch',
      {
        itemList: form.custom.map((e) => {
          return {
            ...e,
            titleImg: (e.titleImg || []).toString(),
            customAdvertisingImg: (e.customAdvertisingImg || []).toString(),
          }
        }),
      },
      '/api'
    ).then((e) => {
      console.log('adasda')
    })
  })
}

function openCommodityList(id, title, list = [], mode = '', showSave = true) {
  showModal({
    title: '商品列表',
    width: 1200,
    view: TickerListModel,
    viewProps: { id, title, listData: list, mode, showSave },
    success: ({ data, setHidden }) => {
      setHidden()
    },
  })
}

function openTabsCommodityList(id, title, list = [], data) {
  return new Promise(async (resolve) => {
    list = list.map((e) => ({
      ...e,
      name: e.name || e.productName,
    }))
    showModal({
      title: '商品列表',
      width: 1200,
      view: TabsTickerListModel,
      viewProps: { id, title, listData: list, data },
      success: ({ data, setHidden }) => {
        resolve(data)
        setHidden()
      },
    })
  })
}

function getColumns3(formData) {
  return [
    {
      dataIndex: 'content',
      title: '自定义名称',
      width: 200,
      customRender: function (text, records, index, h) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={text}
              placeholder="请输入自定义名称"
              style={{ width: '150px' }}
              onChange={(e) => {
                records.content = e.target.value
              }}
            />
            <FormUpload
              item={{}}
              style={{ width: '100px', height: '100px', transform: 'scale(0.5)', marginLeft: '-10px' }}
              value={records.titleImg}
              onChange={(data) => {
                records.titleImg = data
              }}
            />
          </div>
        )
      },
    },
    {
      title: '风格',
      dataIndex: 'itemConfigCode',
      customRender: function (text, records, index, h) {
        let url = ''
        if (records.itemConfigCode) {
          url = formData.styleList.find((e) => e.code == records.itemConfigCode).previewUrl
        }
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Select
              value={records.itemConfigCode}
              style={{ width: '100%', minWidth: '135px' }}
              onChange={(data) => {
                records['itemConfigCode'] = data
              }}
              getPopupContainer={(e) => {
                return e.closest('table')
              }}
            >
              {(formData.styleList || []).map((e) => {
                return <Select.Option value={e.code}>{e.name}</Select.Option>
              })}
            </Select>
            {url && (
              <Popover style={{ marginLeft: '10px' }} trigger="click">
                <div style={{ whiteSpace: 'nowrap' }}>点击预览</div>
                <template slot="content">
                  <img src={url} style={{ backgroundSize: '100% 100%' }} />
                </template>
              </Popover>
            )}
            {records.itemConfigCode == 'custom_advertising' && (
              <FormUpload
                item={{}}
                style={{ width: '100px', height: '100px', transform: 'scale(0.5)', marginLeft: '-10px' }}
                value={records.customAdvertisingImg}
                onChange={(data) => {
                  records.customAdvertisingImg = data
                }}
              />
            )}
          </div>
        )
      },
    },
    {
      dataIndex: 'status',
      title: '更多跳转地址',
      width: 150,
      customRender: function (text, records, index, h) {
        return (
          <Select
            value={records.itemJumpConfigCode}
            style={{ width: '100%' }}
            onChange={(data) => {
              records['itemJumpConfigCode'] = data
            }}
            getPopupContainer={(e) => {
              return e.closest('table')
            }}
          >
            {(formData.config || []).map((e) => {
              return <Select.Option value={e.code}>{e.name}</Select.Option>
            })}
          </Select>
        )
      },
    },
    {
      dataIndex: 'status',
      title: '页面显示',
      width: 100,
      customRender: function (text, records, index, h) {
        return (
          <Switch
            checked={text == '1' ? false : true}
            onChange={(state) => {
              records.status = state ? '0' : '1'
              // formData.list[index]['status'] = state ? '0' : '1'
            }}
          />
        )
      },
    },
    {
      title: '优先级',
      dataIndex: 'sort',
      width: '50px',
      customRender: function (text, records, index, h) {
        return (
          <Input
            value={text}
            placeholder="请输入优先级排序"
            onChange={(e) => {
              records.sort = e.target.value
            }}
          />
        )
      },
    },
    {
      dataIndex: 'action',
      title: '操作',
      type: 'buttonGroup',
      align: 'center',
      typeData: ({ records, index }) => {
        return [
          {
            display: !(records.itemConfigCode == 'custom_advertising' || records.itemConfigCode == 'scenic_guide'),
            name: '商品列表',
            onClick: () => {
              openTabsCommodityList(
                records.id,
                records.code == 'farm_ticket_one' ? '注：前端只展示排序前18商品信息' : '注：前端只展示排序前9商品信息',
                records.relationList,
                records
              ).then((data) => {
                records.relationList = data
              })
            },
          },
          {
            display: !(records.itemConfigCode == 'custom_advertising' || records.itemConfigCode == 'scenic_guide'),
            name: '添加商品',
            onClick: () => {
              if (!records.itemConfigCode) {
                message.error('请选择风格')
                return
              }
              if (records.itemConfigCode == 'waterfall_flow' || records.itemConfigCode == 'flash_sale_style') {
                openAddTabsGoods(records, { mode: true })
              } else {
                openAddTabsGoods(records, { mode: false }, formData)
              }
            },
          },
          {
            display: records.itemConfigCode == 'scenic_guide',
            name: '编辑',
            onClick: () => {
              addMapInfo(records, { mode: false }, formData)
            },
          },
          {
            name: '删除',
            popTitle: '删除',
            type: 'pop',
            display: true,
            onClick: () => {
              if (records.id) {
                deleteAction(`/config/farmPalmItem/deleteCustom?id=${records.id}`, {}, '/api').then((e) => {
                  message.success('删除成功')
                  formData.custom.splice(index, 1)
                })
              } else {
                formData.custom.splice(index, 1)
              }
            },
          },
        ].filter((e) => (e.display == undefined ? true : e.display))
      },
    },
  ]
}

function getColumns1(formData) {
  return [
    {
      dataIndex: 'content',
      title: '模块名称',
    },
    {
      dataIndex: 'name',
      title: '自定义名称',
      customRender: function (text, records, index, h) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={text}
              placeholder="请输入自定义名称"
              style={{ width: '150px' }}
              onChange={(e) => {
                records.name = e.target.value
              }}
            />
            <FormUpload
              item={{}}
              style={{ width: '100px', height: '100px', transform: 'scale(0.5)', marginLeft: '-10px' }}
              value={records.titleImg}
              onChange={(data) => {
                records.titleImg = data
              }}
            />
          </div>
        )
      },
    },
    {
      dataIndex: 'status',
      title: '页面显示',
      customRender: function (text, records, index, h) {
        return (
          <Switch
            checked={text == '1' ? false : true}
            onChange={(state) => {
              records['status'] = state ? '0' : '1'
            }}
          />
        )
      },
    },
    {
      dataIndex: 'sort',
      title: '优先级',
      customRender: function (text, records, index, h) {
        return (
          <Input
            value={text}
            placeholder="请输入优先级排序"
            onChange={(e) => {
              records.sort = e.target.value
            }}
          />
        )
      },
    },
  ]
}

function openAddTabsGoods(records, { mode = true } = {}, formData) {
  showModal({
    title: '添加商品',
    width: 900,
    view: AddTicketsTabsModal,
    viewProps: {
      relationList: records.relationList,
      mode,
    },
    success: ({ data, setHidden }) => {
      if (data.length == 0) {
        message.error('请选择数据')
      } else {
        const rows = data.rows
          .filter((e) => e.id || e.productId)
          .map((e) => ({
            productId: e.productId || e.id,
            name: e.name,
            productName: e.productName,
            linkShopName: e.linkShopName,
            previewUrl: e.previewUrl,
            secondCategoryCode: e.categoryCode || e.secondCategoryCode,
            itemCategoryCode: e.itemCategoryCode,
          }))
        records.relationList = [...rows]
        setHidden()
      }
    },
  })
}

function addMapInfo(records, { mode = true } = {}, formData) {
  showModal({
    title: '编辑',
    width: 900,
    view: AddMapLngLat,
    viewProps: {
      records: records,
      mode,
    },
    success: ({ data, setHidden }) => {
      records.initialLevel = data.initialLevel
      records.highestLevel = data.highestLevel
      records.lowestLevel = data.lowestLevel
      records.lnglat = data.lnglat
      setHidden()
    },
  })
}

// function openAddTabsVideo(){
//   showModal({
//     title: '添加视频',
//     width: 900,
//     view: AddHomeVideo,
//     viewProps: {
//       relationList: records.relationList,
//       mode,
//     },
//     success: ({ data, setHidden }) => {
//       if (data.length == 0) {
//         message.error('请选择数据')
//       } else {
//         records.relationList = data.rows
//           .filter((e) => e.id || e.productId)
//           .map((e) => ({
//             productId: e.productId || e.id,
//             name: e.name,
//             productName: e.productName,
//             linkShopName: e.linkShopName,
//             previewUrl: e.previewUrl,
//             secondCategoryCode:e.categoryCode,
//             itemCategoryCode:e.itemCategoryCode
//           }))
//         console.log('records.relationList', data.rows, records.relationList)
//         setHidden()
//       }
//     },
//   })
// }

function openAddGoods(id) {
  showModal({
    title: '添加商品',
    width: 900,
    view: AddTicketsModal,
    success: ({ data, setHidden }) => {
      if (Array.isArray(data)) {
        if (data.length == 0) {
          message.error('请选择商品')
        } else {
          postAction('/farmPalmItemRelation/save', {
            palmItemId: id,
            productIdList: data.map((e) => e.id),
          }).then((e) => {
            message.success('保存成功')
            setHidden()
          })
        }
      } else {
        message.error('请选择商品')
      }
    },
  })
}

function getColumns2(formData) {
  return [
    {
      dataIndex: 'name',
      title: '模块名称',
    },
    {
      dataIndex: 'content',
      title: '自定义名称',
      customRender: function (text, records, index, h) {
        return (
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <Input
              value={text}
              placeholder="请输入自定义名称"
              style={{ width: '150px' }}
              onChange={(e) => {
                records.content = e.target.value
              }}
            />
            <FormUpload
              item={{}}
              style={{ width: '100px', height: '100px', transform: 'scale(0.5)', marginLeft: '-10px' }}
              value={records.titleImg}
              onChange={(data) => {
                records.titleImg = data
              }}
            />
          </div>
        )
      },
    },
    {
      dataIndex: 'status',
      title: '页面显示',
      customRender: function (text, records, index, h) {
        return (
          <Switch
            checked={text == '1' ? false : true}
            onChange={(state) => {
              records['status'] = state ? '0' : '1'
            }}
          />
        )
      },
    },
    {
      dataIndex: 'sort',
      title: '优先级',
      customRender: function (text, records, index, h) {
        return (
          <Input
            value={text}
            placeholder="请输入优先级排序"
            onChange={(e) => {
              records.sort = e.target.value
            }}
          />
        )
      },
    },
    {
      dataIndex: 'action',
      title: '操作',
      type: 'buttonGroup',
      align: 'center',
      typeData: ({ records }) => {
        if (records.code == 'farm_ticket_one' || records.code == 'farm_ticket_two') {
          return [
            {
              name: '商品列表',
              onClick: () => {
                openCommodityList(
                  records.id,
                  records.code == 'farm_ticket_one'
                    ? '注：前端只展示排序前18商品信息'
                    : '注：前端只展示排序前9商品信息',
                  records.relationList,
                  false
                )
              },
            },
            {
              name: '添加商品',
              onClick: () => {
                openAddGoods(records.id)
              },
            },
          ]
        }
      },
    },
  ]
}
function getForm(formData) {
  return [
    {
      type: 'radio',
      name: '首页风格',
      key: 'homeStyleType',
      labelCol: 5,
      wrapperCol: 19,
      onChange: (state) => {
        getAction(`/config/farmPalmItem/list?homeStyleType=${state}`).then((e) => {
          formData.list = e.data
        })
      },
      typeData: [
        {
          name: '老板风格',
          value: '0',
        },
        {
          name: '文旅风格',
          value: '1',
        },
      ],
    },
    {
      type: 'upload',
      name: '首页底图',
      key: 'backgroundImg',
      labelCol: 5,
      wrapperCol: 19,
      display: formData.homeStyleType == '1',
    },
    {
      type: 'table',
      name: '自定义管理',
      dataSource: formData.custom,
      labelCol: 5,
      wrapperCol: 19,
      columns: getColumns3(formData),
      showFootButton: true,
      showPagination: false,
      onAddData: () => {
        return {
          name: '',
          itemConfigCode: '',
          status: '0',
          sort: '1',
          id: '',
          titleImg: [],
          itemJumpConfigCode: '',
          customAdvertisingImg: [],
        }
      },
    },
    {
      type: 'table',
      name: '分类管理',
      dataSource: formData.list,
      labelCol: 5,
      wrapperCol: 19,
      columns: formData.homeStyleType == '0' ? getColumns1(formData) : getColumns2(formData),
    },
  ].filter((e) => (e.display != undefined ? e.display : true))
}
export default {
  type: 'cardForm',
  url: '/farmPalmHomePageStyleConfig/getDetail',
  onFetchInit,
  onSubmit,
  data: (netdata) => {
    return [
      {
        form: getForm,
      },
    ]
  },
}
